
import { Vue, Options } from "vue-class-component";
import ApiApp from "@/api/ApiApp";
import InformationPopup from "@/components/InformationPopup/InformationPopup.vue";
import InformationActionsBtn from "@/components/InformationPopup/InformationActionsBtn.vue";
@Options({
    components: {
        InformationPopup,
        InformationActionsBtn,
    },
    watch: {
        $route(to: any, from: any) {
            this.visibleHeader = false;
        },
    },
})
export default class Header extends Vue {
    phone = "";

    public ShowLogOutPopup = false;
    visibleHeader = false;
    visibleRefer = false;
    isHaveNotifications = false;

    env() {
        return process.env;
    }

    created() {
        (window as any).header = this;
        let phone = localStorage.getItem("phone");

        if (phone) {
            const formattedPhone = phone.split("");
            formattedPhone.splice(2, 0, " ");
            this.phone = "+" + formattedPhone.join("");
        }

        this.GetExternalConfig();
        this.CheckNotification();
    }

    private async GetExternalConfig(): Promise<void> {
        const response = await ApiApp.GetExternalConfig();

        if (response && response?.data?.mgmSettings?.isActive) {
            this.visibleRefer = true;
        }
    }

    private async CheckNotification(): Promise<void> {
        const response = await ApiApp.CheckNotification();

        if (response && response.data) this.isHaveNotifications = true;
    }

    Show() {
        this.visibleHeader = true;
    }
    HideLogOutPopup() {
        this.ShowLogOutPopup = false;
    }

    Hide() {
        this.visibleHeader = false;
    }

    private async Logout(): Promise<void> {
        await ApiApp.LogOut();
        localStorage.removeItem("phone");
        document.cookie.split(";").forEach(function (c) {
            document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
        this.$router.replace("/login");
        console.log("To Login --> Logout");
    }
}
