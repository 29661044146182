import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import login from "@/router/enter";
import topup from "@/router/topup";
import support from "@/router/support";
import settings from "@/router/settings";
import activation from "@/router/activation";
import options from "@/router/options";
import offers from "@/router/offers";
import recurring from "@/router/recurring";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/tech",
        name: "Tech",
        component: () => import("@/views/Tech.vue"),
        meta: {
            layout: "auth-layout",
            title: "1Mobile | WSC",
        },
    },
    {
        path: "/",
        name: "Index",
        component: () => import("@/views/IndexPage.vue"),
        meta: {
            title: "1Mobile | WSC",
        },
    },
    ...recurring,
    ...login,
    ...topup,
    ...support,
    ...settings,
    ...activation,
    ...offers,
    ...options,
    {
        path: "/refer",
        component: () => import("@/views/ReferFriendPage.vue"),
        meta: {
            title: "Refer a friend | WSC",
        },
    },
    {
        path: "/notification",
        component: () => import("@/views/NotificationPage.vue"),
        meta: {
            title: "Notifications | WSC",
        },
    },
    {
        path: "/history",
        component: () => import("@/views/HistoryPage.vue"),
        meta: {
            title: "Usage history | WSC",
        },
    },
];

const DEFAULT_TITLE = "WSC";
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    //console.log("to, to);
    /*if (to.name != "Tech") {
        return next({ name: "Tech" });
    } else {
        return next();
    }*/
    const hasExpired = localStorage.getItem('CookieExpired');
    if (to.name == "RecurringFailed" && to.query.esito === 'ANNULLO' ) {
        console.log('ANNULLO transaction', to);
        return next({ name: "Index" });
    }
    if (hasExpired) {
        if (to.meta.noAuth === true) {
            console.log("Nice");
            return next();
        } else {
            console.log('BeforeEach to Login')
            return next({ name: "Login" });
        }
    } else {
        return next();
    }
});

router.afterEach((to, from) => {
    (document as any).title = to.meta.title || DEFAULT_TITLE;
});
export default router;
